<template>
  <div class="card">
    <h5 class="card-header">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Mes actual
      </span>
      Pendientes
    </h5>
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 me-3">
          <div class="avatar">
            <div class="avatar-title rounded bg-primary bg-gradient">
              <eva-icon
                name="pie-chart-2"
                class="fill-white"
              ></eva-icon>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <p class="text-muted mb-1">Facturas por cobrar</p>
          <h4 class="mb-0">{{ formatoMoneda(total) }}</h4>
        </div>
        <div class="flex-shrink-0 align-self-end ms-2">
          <div class="badge rounded-pill font-size-13 badge-soft-primary">
            {{ porcentaje }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Componente de facturas pendientes del mes
 */
 var accounting = require('accounting/accounting.js')
import FacturaSrv from '@/services/FacturaSrv.js'
export default {
  name: 'CmpFacturasPendientesPagoMes',
  data() {
    return {
      total: 0,
      totalIngresosMes: 0,
      porcentaje: 100,
      intervals: {
        actualizacion: null
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this
    self.refrescarTotal()
    // Actualización periódica del total
    self.intervals.actualizacion = setInterval(function() {
      self.refrescarTotal()
    }, 120000)

    iu.bus.on('total-ingresos-mes', function(totalIngresosMes) {
      self.totalIngresosMes = totalIngresosMes
      self.actualizarPorcentaje()
    })
  },
  beforeUnmount: function() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizarPorcentaje: function() {
      this.porcentaje = Math.round(
        (parseFloat(this.total) /
          (parseFloat(this.total) + parseFloat(this.totalIngresosMes))) *
          100
      )
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    refrescarTotal: function() {
      var self = this

      FacturaSrv.totalPendientesPagoEnMes().then(response => {
        let total = response.data
        self.total = total
        iu.bus.emit('total-facturas-pendientes-pago-cargadas', total)
        self.actualizarPorcentaje()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener el total de las facturas que están pendientes de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
</style>