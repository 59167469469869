<template>
  <div class="modal fade" ref="mdlUsuarios" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Filtro por usuarios
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Selecciona los usuarios para filtrar los abonos recibidos.
          </p>
          <table class="table table-hover">
            <tbody>
              <tr v-for="usuario in usuarios" :key="usuario.id">
                <td>
                  <div class="form-check form-switch form-switch-right form-switch-md">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="'usuario-' + usuario.id"
                      @change="seleccionar()"
                      v-model="usuario.palanca_activo"
                    />
                    <label
                      class="form-check-label"
                      :for="'usuario-' + usuario.id"
                    >
                      {{usuario.nombre ? usuario.nombre : ''}}
                      {{usuario.apellido_paterno ? usuario.apellido_paterno : ''}}
                      {{usuario.apellido_materno ? usuario.apellido_materno : ''}}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioSrv from '@/services/UsuarioSrv.js'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlUsuario',
  data() {
    return {
      ids_usuarios: [],
      usuarios: []
    }
  },
  methods: {
    seleccionar() {
      var self = this

      let idsUsu = self.usuarios.filter(usu => {
        if(usu.palanca_activo == true) return usu.id
      })

      idsUsu = idsUsu.map(id => id.id)

      this.$emit('ids:usuarios:seleccionado', idsUsu)
    },
    mostrar(ids_usuarios) {
      var self = this
      self.ids_usuarios = ids_usuarios
      var modal = new Modal(this.$refs.mdlUsuarios)
      modal.show()
      this.refrescarUsuarios()
    },
    refrescarUsuarios() {
      let self = this

      UsuarioSrv.usuariosJSON().then(response => {
        var usuarios = response.data

        usuarios.push({
          id: -1,
          nombre: 'Mi servicio de internet',
          palanca_activo: false
        })

        usuarios.forEach(usuario => {
          let id = self.ids_usuarios.find(id => {
            return id == usuario.id
          })
          usuario.palanca_activo = id ? true : false
        });

        self.usuarios = usuarios
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>