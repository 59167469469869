<template>
  <Layout tituloPagina="Facturas | Reportes">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="reportes"></cmp-secciones>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-lg-3 col-xs-6">
          <cmp-ingresos-mes></cmp-ingresos-mes>
        </div>
        <div class="col-lg-3 col-xs-6">
          <cmp-facturas-pendientes-pago-mes></cmp-facturas-pendientes-pago-mes>
        </div>
        <div class="col-lg-3 col-xs-6">
          <cmp-facturas-morosas></cmp-facturas-morosas>
        </div>
        <div class="col-lg-3 col-xs-6">
          <cmp-facturas-pagadas-mes></cmp-facturas-pagadas-mes>
        </div>
      </div>

      <!-- Componente de gráficas de ingresos -->
      <cmp-grafica-ingresos
        :seccion-activa="seccionActiva"
      ></cmp-grafica-ingresos>
      <!-- Fin de componente de gráfica de ingresos -->

      <!-- Componente de resumen del día -->
      <cmp-resumen-dia></cmp-resumen-dia>
      <!-- Fin de componente de resumen del día -->
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import CmpSecciones from '../CmpSecciones.vue'
import CmpIngresosMes from './CmpIngresosMes.vue'
import CmpFacturasPendientesPagoMes from './CmpFacturasPendientesPagoMes.vue'
import CmpFacturasMorosas from './CmpFacturasMorosas.vue'
import CmpFacturasPagadasMes from './CmpFacturasPagadasMes.vue'
import CmpGraficaIngresos from './CmpGraficaIngresos.vue'
import CmpResumenDia from './CmpResumenDia.vue'

export default {
  name: 'ReportesFacturas',
  components: {
    Layout,
    CmpSecciones,
    CmpIngresosMes,
    CmpFacturasPendientesPagoMes,
    CmpFacturasMorosas,
    CmpFacturasPagadasMes,
    CmpGraficaIngresos,
    CmpResumenDia
  },
  data() {
    return {
      seccionActiva: 'mensual'
    }
  }
}
</script>