<template>
  <div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ingresos
        </h4>
        <div>
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-light btn-sm"
              :class="{ 'bg-secondary text-white': grafica == 'mensual' }"
              @click="cargarGrafica('mensual')"
            >
              Mensual
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              :class="{ 'bg-secondary text-white': grafica == 'anual' }"
              @click="cargarGrafica('anual')"
            >
              Anual
            </button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-5">
            <div id="grafica-ingresos-mes-anual">
              <apexchart
                class="apex-charts"
                height="350"
                dir="ltr"
                :series="ingresos_anio_mes.series"
                :options="ingresos_anio_mes.chartOptions"
              ></apexchart>
            </div>
          </div>
          <div class="col-lg-6 col-md-7">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group" v-show="grafica=='mensual'">
                  <span class="input-group-text">Mes</span>
                  <button class="btn btn-secondary" @click="mesAnterior()">
                    <i class="mdi mdi-chevron-left"></i>
                  </button>
                  <select
                    v-model="mes"
                    class="form-select"
                    @change="
                      refrescarIngresosDiariosDelMes(),
                      cargarGrafica(grafica)
                    "
                  >
                    <option
                      :value="m"
                      v-for="m in 12"
                      :key="m"
                    >
                      {{meses[m-1]}}
                    </option>
                  </select>
                  <button class="btn btn-secondary" @click="mesSiguiente()">
                    <i class="mdi mdi-chevron-right"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text">Año</span>
                  <button class="btn btn-secondary" @click="anioAnterior()">
                    <i class="mdi mdi-chevron-left"></i>
                  </button>
                  <input
                    type="text"
                    class="form-control"
                    v-model="anio"
                    @change="
                      (grafica=='mensual'?refrescarIngresosDiariosDelMes():refrescarIngresosMensualesDelAnio()),
                      cargarGrafica(grafica)
                    "
                  />
                  <button class="btn btn-secondary" @click="anioSiguiente()">
                    <i class="mdi mdi-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-sm-6">
                <apexchart
                  class="apex-charts"
                  height="300"
                  :series="pagos_puntuales_mora.series"
                  :options="pagos_puntuales_mora.chartOptions"
                ></apexchart>
              </div>
              <div class="col-sm-6">
                <apexchart
                  class="apex-charts"
                  height="300"
                  :series="pagos_efectivo_deposito.series"
                  :options="pagos_efectivo_deposito.chartOptions"
                ></apexchart>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="offset-md-4 col-md-4 text-center">
                <strong>
                  Total del {{grafica=='mensual'?'mes':'año'}} {{formatoMoneda(total)}}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Componente de gráficas de ingresos
 */
import moment from 'moment'
import accounting from 'accounting/accounting.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import AbonoSrv from '@/services/AbonoSrv.js'

export default {
  name: 'CmpGraficaIngresos',
  props: ['seccionActiva'],
  data() {
    return {
      hrr: iu.hrr,
      fechaHoy: null,
      gped: null,
      gppm: null,
      grafica: 'mensual',
      ingresosPorDia: [],
      ingresosPorMes: [],
      mes: 1,
      anio: 2018,
      meses: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      totalPagosConMora: 0,
      totalPagosPuntuales: 0,
      totalesPorMetodosPago: [],
      intervals: {
        totales: null
      },

      ingresos_anio_mes: {
        series: [{data: []}],
        chartOptions: {
          chart: {
            height: 350,
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 15,
              columnWidth: "85%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 2,
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: [],
            tickPlacement: "on",
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [100, 0, 100],
            },
          },
          title: {
            text: "",
            floating: true,
            offsetY: 320,
            align: "center",
            style: {
              color: "#444",
              fontWeight: 500,
            },
          },
          colors: ["#63AD6F"],
        },
      },

      pagos_puntuales_mora: {
        series: [],
        chartOptions: {
          chart: {
            height: 300,
            type: "donut",
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
            },
          },
          dataLabels: {
            enabled: false,
          },
          labels: [],
          fill: {
            type: "gradient",
          },
          legend: {
            position: "bottom",
            show: false
          },
          title: {
            text: "",
            style: {
              fontWeight: 500,
            },
          },
          colors: [],
        }
      },
      pagos_efectivo_deposito: {
        series: [],
        chartOptions: {
          chart: {
            height: 300,
            type: "donut",
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
            },
          },
          dataLabels: {
            enabled: false,
          },
          labels: [],
          fill: {
            type: "gradient",
          },
          legend: {
            position: "bottom",
            show: false
          },
          title: {
            text: "",
            style: {
              fontWeight: 500,
            },
          },
          colors: [],
        }
      }
    }
  },
  computed: {
    total() {
      let total = 0
      this.totalesPorMetodosPago.forEach(t => total += t.total_pagos) 

      return total
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  beforeUnmount() {
    var self = this

    // Eliminación de los intervalos
    clearInterval(self.intervals.totales)
  },
  created: function() {
    var self = this

    // Carga de la fecha actual
    var fechaProm = SistemaSrv.fecha()
    fechaProm.then(response => {
      self.fechaHoy = response.data
      var fecha = moment(self.fechaHoy, 'YYYY-MM-DD')
      self.mes = fecha.format('M')
      self.anio = fecha.format('YYYY')

      // Intervalo para refrescar la grafica de totales de pagos puntuales y con mora
      // Cada 5 minutos
      self.intervals.totales = setInterval(function() {
        self.refrescarTotalesPagosPuntualesYConMora()
      }, 300000)
    }).catch(error => {
      let mensaje
      try {
        mensaje = error.response.data.message
      } catch (e) {
        mensaje = ''
      }
      mensaje != '' ? iu.msg.error(mensaje) : null
      console.log(error)
    })

    // Control de eventos
    fechaProm.then(response => {
      if (self.grafica == 'mensual') self.refrescarIngresosDiariosDelMes()
      else if (self.grafica == 'anual')
        self.refrescarIngresosMensualesDelAnio()
    }).catch(error => {
      let mensaje
      try {
        mensaje = error.response.data.message
      } catch (e) {
        mensaje = ''
      }
      mensaje != '' ? iu.msg.error(mensaje) : null
      console.log(error)
    })
  },
  mounted: function() {
    var self = this

    iu.spinner.mostrar('#grafica-ingresos-mes-anual')

    setTimeout(function() {
      self.refrescarTotalesPagosPuntualesYConMora()
      self.refrescarTotalesPorMetodosPago()
    }, 1000)
  },

  methods: {
    anioAnterior() {
      if(this.anio > 1970) this.anio--
      else return
      
      this.cargarGrafica(this.grafica)
    },

    anioSiguiente() {
      this.anio++
      this.cargarGrafica(this.grafica)
    },

    cargarGrafica: function(grafica) {
      var self = this
      self.grafica = grafica

      switch (grafica) {
        case 'mensual':
          self.refrescarIngresosDiariosDelMes()
          break
        case 'anual':
          self.refrescarIngresosMensualesDelAnio()
          break
      }

      self.refrescarTotalesPagosPuntualesYConMora()
      self.refrescarTotalesPorMetodosPago()
    },
    construirGraficaAnio: function() {
      var self = this

      var data = []
      var labels = []
      self.ingresosPorMes.forEach(function(ing, index) {
        labels.push([self.meses[ing.mes-1]]);
        data.push(parseFloat((ing.total_mes+1 || 0).toFixed(2)))
      })
      
      self.ingresos_anio_mes.series = [{
        name: 'Total del mes',
        data: data,
      }]

      self.ingresos_anio_mes.chartOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 15,
            columnWidth: "85%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: labels,
          tickPlacement: "on",
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [100, 0, 100],
          },
        },
        title: {
          text: "Ingresos por mes",
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            color: "#444",
            fontWeight: 500,
          },
        },
        colors: ["#63AD6F"],
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },
    construirGraficaMes: function() {
      var self = this

      var data = []
      var labels = []
      self.ingresosPorDia.forEach(function(ing) {
        labels.push(ing.dia)
        data.push(parseFloat((ing.total_dia|| 0).toFixed(2)))
      })

      self.ingresos_anio_mes.series = [{
        name: 'Total del dia',
        data: data
      }]

      self.ingresos_anio_mes.chartOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 7,
            columnWidth: "85%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: labels,
          tickPlacement: "on",
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [100, 0, 100],
          },
        },
        title: {
          text: "Ingresos por dias del mes de "+self.meses[self.mes-1],
          floating: true,
          offsetY: 0,
          align: "center",
          style: {
            color: "#444",
            fontWeight: 500,
          },
        },
        colors: ["#63AD6F"],
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },

    construirGraficaTotalesPorMetodosPago: function() {
      var self = this,
        labels = self.totalesPorMetodosPago.map( t => t.metodo_pago ),
        data = self.totalesPorMetodosPago.map( t => t.total_pagos),
        backgroundColor = [
          '#a3e1d4', '#b5b8cf', '#20B2AA', '#4682B4', '#708090',
          '#90EE90', '#800080', '#FA8072', '#6A5ACD', '#FF6347',
          '#9ACD32', '#EE82EE', '#D8BFD8', '#D2B48C', '#2E8B57',
        ];

      // Si la cantidad de colores necesarios supera la paleta base, se generan colores adicionales aleatorios
      if (self.totalesPorMetodosPago.lenght > 15 ) {
        for (let i = 0; i < (self.totalesPorMetodosPago+15); i++) {
          let color = "";
          for(let c=0;c<6;c++){
            color = color + self.generarLetra();
          }
          backgroundColor.push("#" + color)
        }
      }
      self.pagos_efectivo_deposito.series = data
      self.pagos_efectivo_deposito.chartOptions = {
        chart: {
          height: 300,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: labels,
        fill: {
          type: "gradient",
        },
        legend: {
          position: "bottom",
          show: true,
          formatter: function(val, opts) {
            return val + " - " + self.formatoMoneda(opts.w.globals.series[opts.seriesIndex])
          },
        },
        title: {
          text: "Grafica de pagos en efectivo y deposito",
          style: {
            fontWeight: 500,
          }
        },
        colors: backgroundColor,
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },

    construirGraficaPagosPuntualesYMora: function() {
      var self = this

      self.pagos_puntuales_mora.series = [parseFloat((self.totalPagosPuntuales || 0).toFixed(2)), parseFloat((self.totalPagosConMora || 0).toFixed(2))]
      self.pagos_puntuales_mora.chartOptions = {
        chart: {
          height: 300,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: ['Pagos puntuales', 'Pagos con mora'],
        fill: {
          type: "gradient",
        },
        legend: {
          position: "bottom",
          show: true,
          formatter: function(val, opts) {
            return val + " - " + self.formatoMoneda(opts.w.globals.series[opts.seriesIndex])
          },
        },
        title: {
          text: "Grafica de pagos puntuales y con mora",
          style: {
            fontWeight: 500,
          },
        },
        colors: ['#57C9EB', '#7F838B'],
        tooltip: {
          y: {
            formatter: function (val) {
              return self.formatoMoneda(val)
            }
          }
        }
      }
    },
    
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })
    },

    generarLetra: function(){
      var letras = ["A","B","C","D","E","F","0","1","2","3","4","5","6","7","8","9"];
      var numero = (Math.random()*15).toFixed(0);
      return letras[numero];
    },

    mesAnterior() {
      if(this.mes > 1) this.mes--
      else {
        this.mes = 12
        this.anio--
      }

      this.cargarGrafica(this.grafica)
    },

    mesSiguiente() {
      if(this.mes < 12) this.mes++
      else {
        this.mes = 1
        this.anio++
      }

      this.cargarGrafica(this.grafica)
    },

    refrescarIngresosDiariosDelMes: function() {
      var self = this
      var anio = self.anio
      var mes = self.mes

      iu.spinner.mostrar('#grafica-ingresos-mes-anual')

      let params = { anio: anio, mes: mes }

      AbonoSrv.totalesPorDiaEnMes(params).then(response => {
        let ingresosPorDia = response.data
        self.ingresosPorDia = ingresosPorDia
        self.construirGraficaMes()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los totales de cada mes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#grafica-ingresos-mes-anual')
      })
    },
    refrescarIngresosMensualesDelAnio: function() {
      var self = this
      var anio = self.anio
      let params = { anio: anio }

      iu.spinner.mostrar('#grafica-ingresos-mes-anual')

      AbonoSrv.totalesPorMesEnAnio(params).then(response => {
        let ingresosPorMes = response.data
        self.ingresosPorMes = ingresosPorMes
        self.construirGraficaAnio()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los totales de cada mes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#grafica-ingresos-mes-anual')
      })
    },
    refrescarTotalesPorMetodosPago: function() {
      var self = this,
        anio = self.anio,
        mes = self.mes,
        config = {}

      iu.spinner.mostrar('#grafica-ingresos-mes-anual')

      if (self.grafica == 'mensual') {
        config = { anio: anio, mes: mes }
      } else if (self.grafica == 'anual') {
        config = { anio: anio }
      }

      AbonoSrv.totalesPorMetodosDePago(config).then(response => {
        self.totalesPorMetodosPago = response.data
        self.construirGraficaTotalesPorMetodosPago()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los totales de pagos en efectivo y depósito'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#grafica-ingresos-mes-anual')
      })
    },
    refrescarTotalesPagosPuntualesYConMora: function() {
      var self = this,
        anio = self.anio,
        mes = self.mes,
        config = {}

      iu.spinner.mostrar('#grafica-ingresos-mes-anual')

      if (self.grafica == 'mensual') {
        config = { anio: anio, mes: mes }
      } else if (self.grafica == 'anual') {
        config = { anio: anio }
      }

      AbonoSrv.totalPagosPuntualesYConMoraDelMes(config).then(response => {
        let totales = response.data
        self.totalPagosPuntuales = totales.total_pagos_puntuales
        self.totalPagosConMora = totales.total_pagos_con_mora

        self.construirGraficaPagosPuntualesYMora()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los pagos puntuales y con mora'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#grafica-ingresos-mes-anual')
      })
    }
  }
}
</script>
